export const formatTimestamp = str =>
  new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(new Date(str))

export const getDelay = (delay = 150) => {
  const ms = Math.floor(
    Math.random() * (delay + 50 - (delay - 50) + 1) + (delay - 50)
  )
  return ms < 0 ? 0 : ms
}

export const getNewMessage = async () => {
  if (typeof window !== 'undefined') {
    const res = await window.fetch(
      'https://raw.githubusercontent.com/torvalds/linux/master/Documentation/filesystems/affs.rst'
    )

    const text = await res.text()

    const words = text
      .replace(/\n/g, ' ')
      .replace(/\W/g, ' ')
      .split(' ')
      .filter(str => str.length > 1 && /^[a-zA-Z]/.test(str))

    const getSentence = (min, max, casing) => {
      const arr = new Array(getRandomNumber(max, min))
        .fill()
        .map(() => words[getRandomNumber(words.length - 1)])

      switch (casing) {
        case 'alllower':
          return arr.join(' ').toLowerCase()

        case 'allupper':
          return arr
            .map(str => str.charAt(0).toUpperCase() + str.slice(1))
            .join(' ')

        case 'firstupper':
          arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1)
          return arr.join(' ')

        default:
          return arr.join(' ')
      }
    }

    const senderName = getSentence(1, 3, 'allupper')
    const subject = getSentence(1, 10, 'firstupper')
    const address = getSentence(1, 3, 'allupper')
    const body = new Array(3)
      .fill()
      .map(
        () =>
          `<p>${new Array(getRandomNumber(1, 7))
            .fill()
            .map(() => getSentence(4, 30, 'firstupper'))
            .join('. ')}.</p>`
      )
      .join('')

    return {
      autoGenerated: true,
      avatarUrl: `https://avatars.githubusercontent.com/u/${getRandomNumber(
        19999,
        1
      )}`,
      body: `<p>${address.charAt(0).toUpperCase()}${address.slice(
        1
      )},</p>${body}<p>— ${senderName}</p>`,
      date: new Date().toISOString(),
      id: getUid(),
      senderEmail: getSentence(3, 3, 'alllower')
        .replace(/ /, '@')
        .replace(/ /, '.'),
      senderName,
      subject: subject.charAt(0).toUpperCase() + subject.slice(1),
      unread: true
    }
  }
}

export const getParam = (url = '', param) =>
  new URLSearchParams(url.split('?')[1]).get(param)

export const getParams = (url = '', nextParams = {}) => {
  const params = new URLSearchParams(
    url.includes('?') ? url.split('?')[1] : undefined
  )

  Object.entries(nextParams).forEach(([key, value]) => {
    if (value === undefined || value === null || value === '') {
      params.delete(key)
    } else {
      params.set(key, value)
    }
  })

  return params.toString()
}

export const getRandomNumber = (y = 100, x = 0) =>
  Math.floor(Math.random() * (y - x + 1) + x)

export const getUid = a =>
  a
    ? (0 | (Math.random() * 16)).toString(16)
    : `${1e11}${1e11}`.replace(/1|0/g, getUid)

export const removeParams = (url = '', params = []) => {
  const nextParams = new URLSearchParams(
    url.includes('?') ? url.split('?')[1] : undefined
  )

  params.forEach(key => {
    nextParams.delete(key)
  })

  return nextParams.toString()
}
